import React from 'react';

export const IconSolanaFM: React.FC = () => {
  return (
    <svg
      className="explorer-icon"
      width="125"
      height="181"
      viewBox="0 0 125 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4592_13822)">
        <path
          d="M0 89.52C0.166191 65.7374 9.72372 42.984 26.5905 26.2164C43.4574 9.44885 66.2668 0.0258011 90.05 0L96.64 0V14.31H90.05C70.0612 14.3331 50.8899 22.2476 36.7062 36.3321C22.5224 50.4166 14.4734 69.5318 14.31 89.52H0Z"
          fill="#8285F1"
        />
        <path
          d="M27.45 180.1V165.79H34C53.9888 165.767 73.1601 157.852 87.3439 143.768C101.528 129.683 109.577 110.568 109.74 90.58H124.05C123.884 114.363 114.326 137.116 97.4595 153.884C80.5926 170.651 57.7832 180.074 34 180.1H27.45Z"
          fill="#8285F1"
        />
        <path
          d="M27.45 152.65V138.34H34C46.7182 138.336 58.9225 133.32 67.9688 124.381C77.0151 115.441 82.1748 103.297 82.33 90.58H96.64C96.4819 107.092 89.8136 122.874 78.0843 134.496C66.3549 146.119 50.5125 152.643 34 152.65H27.45Z"
          fill="#5254C7"
        />
        <path
          d="M27.45 89.52C27.6106 73.016 34.2743 57.2414 45.9944 45.6205C57.7146 33.9996 73.5453 27.4703 90.05 27.45H96.64V41.76H90.05C77.3388 41.7748 65.1446 46.7948 56.107 55.7332C47.0693 64.6717 41.9151 76.8097 41.76 89.52H27.45Z"
          fill="#5254C7"
        />
        <path
          d="M27.45 125.21V110.89H34C39.4402 110.895 44.6669 108.774 48.5656 104.979C52.4643 101.185 54.7266 96.0183 54.87 90.58V90.05C54.8806 80.7257 58.5914 71.7868 65.1875 65.1963C71.7836 58.6059 80.7257 54.9027 90.05 54.9H96.64V69.21H90.05C84.6175 69.2185 79.4025 71.3449 75.5129 75.1373C71.6233 78.9298 69.3658 84.0895 69.22 89.52V89.52V90.05C69.2094 99.3822 65.4934 108.328 58.889 114.921C52.2845 121.515 43.3322 125.215 34 125.21H27.45Z"
          fill="#383885"
        />
      </g>
      <defs>
        <clipPath id="clip0_4592_13822">
          <rect width="124.09" height="180.1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
