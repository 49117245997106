import React from 'react';

export const IconCandyShop: React.FC = () => {
  return (
    <svg width="123" height="36" viewBox="0 0 370 109" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="369.699" height="108.36" rx="16" fill="#7522F5" />
      <path
        d="M32.455 20.24C34.455 20.24 36.035 20.73 37.195 21.71C38.355 22.67 38.935 24.03 38.935 25.79C38.935 27.51 38.355 28.86 37.195 29.84C36.035 30.8 34.455 31.28 32.455 31.28H28.705V37.04H24.625V20.24H32.455ZM31.915 28.34C32.895 28.34 33.635 28.13 34.135 27.71C34.635 27.29 34.885 26.66 34.885 25.82C34.885 24.96 34.635 24.32 34.135 23.9C33.635 23.46 32.895 23.24 31.915 23.24H28.705V28.34H31.915Z"
        fill="white"
      />
      <path
        d="M50.0003 37.28C48.2203 37.28 46.6803 36.94 45.3803 36.26C44.1003 35.56 43.1103 34.57 42.4103 33.29C41.7303 31.99 41.3903 30.44 41.3903 28.64C41.3903 26.84 41.7303 25.3 42.4103 24.02C43.1103 22.72 44.1003 21.73 45.3803 21.05C46.6803 20.35 48.2203 20 50.0003 20C51.7803 20 53.3103 20.35 54.5903 21.05C55.8703 21.73 56.8503 22.72 57.5303 24.02C58.2303 25.3 58.5803 26.84 58.5803 28.64C58.5803 30.44 58.2303 31.99 57.5303 33.29C56.8503 34.57 55.8703 35.56 54.5903 36.26C53.3103 36.94 51.7803 37.28 50.0003 37.28ZM50.0003 34.07C50.9203 34.07 51.7103 33.87 52.3703 33.47C53.0503 33.05 53.5703 32.44 53.9303 31.64C54.2903 30.82 54.4703 29.82 54.4703 28.64C54.4703 27.44 54.2903 26.44 53.9303 25.64C53.5703 24.84 53.0503 24.24 52.3703 23.84C51.7103 23.42 50.9203 23.21 50.0003 23.21C49.0603 23.21 48.2503 23.42 47.5703 23.84C46.9103 24.24 46.4003 24.84 46.0403 25.64C45.6803 26.44 45.5003 27.44 45.5003 28.64C45.5003 29.82 45.6803 30.82 46.0403 31.64C46.4003 32.44 46.9103 33.05 47.5703 33.47C48.2503 33.87 49.0603 34.07 50.0003 34.07Z"
        fill="white"
      />
      <path
        d="M65.0992 37.04L60.1192 20.24H64.4692L66.8392 29.69L67.8892 33.92L71.1892 20.24H75.5092L78.8692 33.95L79.9192 29.69L82.2892 20.24H86.5192L81.5392 37.04H76.5592L74.5192 29.39L73.3792 23.87H73.3192L72.2092 29.39L70.1992 37.04H65.0992Z"
        fill="white"
      />
      <path
        d="M89.3711 37.04V20.24H102.991V23.27H93.4511V27.11H100.891V30.02H93.4511V34.01H103.261V37.04H89.3711Z"
        fill="white"
      />
      <path
        d="M106.715 37.04V20.24H114.695C116.735 20.24 118.335 20.71 119.495 21.65C120.675 22.57 121.265 23.87 121.265 25.55C121.265 27.23 120.675 28.53 119.495 29.45C118.335 30.37 116.735 30.83 114.695 30.83H110.795V37.04H106.715ZM117.785 37.04L113.285 29.84L116.495 28.49L122.165 37.04H117.785ZM110.795 28.07H114.245C115.245 28.07 115.985 27.87 116.465 27.47C116.965 27.07 117.215 26.46 117.215 25.64C117.215 24.82 116.965 24.21 116.465 23.81C115.985 23.39 115.245 23.18 114.245 23.18H110.795V28.07Z"
        fill="white"
      />
      <path
        d="M125.201 37.04V20.24H138.821V23.27H129.281V27.11H136.721V30.02H129.281V34.01H139.091V37.04H125.201Z"
        fill="white"
      />
      <path
        d="M142.545 37.04V20.24H149.535C152.335 20.24 154.495 20.97 156.015 22.43C157.555 23.89 158.325 25.95 158.325 28.61C158.325 31.31 157.555 33.39 156.015 34.85C154.495 36.31 152.335 37.04 149.535 37.04H142.545ZM146.625 33.89H149.595C151.135 33.89 152.285 33.44 153.045 32.54C153.825 31.62 154.215 30.31 154.215 28.61C154.215 26.93 153.825 25.64 153.045 24.74C152.285 23.84 151.135 23.39 149.595 23.39H146.625V33.89Z"
        fill="white"
      />
      <path
        d="M171.256 37.04V20.24H179.476C181.556 20.24 183.086 20.64 184.066 21.44C185.066 22.22 185.566 23.33 185.566 24.77C185.566 25.73 185.286 26.55 184.726 27.23C184.166 27.91 183.346 28.36 182.266 28.58V28.61C183.546 28.73 184.496 29.16 185.116 29.9C185.736 30.62 186.046 31.49 186.046 32.51C186.046 33.93 185.506 35.04 184.426 35.84C183.366 36.64 181.806 37.04 179.746 37.04H171.256ZM175.186 34.19H179.206C180.106 34.19 180.786 34.02 181.246 33.68C181.726 33.32 181.966 32.79 181.966 32.09C181.966 31.37 181.726 30.84 181.246 30.5C180.786 30.14 180.106 29.96 179.206 29.96H175.186V34.19ZM175.186 27.23H178.816C179.716 27.23 180.386 27.06 180.826 26.72C181.266 26.38 181.486 25.86 181.486 25.16C181.486 24.46 181.266 23.94 180.826 23.6C180.386 23.26 179.716 23.09 178.816 23.09H175.186V27.23Z"
        fill="white"
      />
      <path
        d="M193.501 30.77L187.231 20.24H191.551L194.281 25.22L195.601 27.8L196.951 25.22L199.681 20.24H203.881L197.611 30.77V37.04H193.501V30.77Z"
        fill="white"
      />
      <path
        d="M30.25 55.8838H49C51.1574 55.8838 52.9062 57.6327 52.9062 59.79V78.54C52.9062 80.6974 51.1574 82.4463 49 82.4463H30.25C28.0926 82.4463 26.3438 80.6974 26.3438 78.54V59.79C26.3438 57.6327 28.0926 55.8838 30.25 55.8838Z"
        stroke="white"
        strokeWidth="4.6875"
      />
      <path
        d="M32.5938 49.2432H46.6562C46.872 49.2432 47.0469 49.4181 47.0469 49.6338C47.0469 49.8495 46.872 50.0244 46.6562 50.0244H32.5938C32.378 50.0244 32.2031 49.8495 32.2031 49.6338C32.2031 49.4181 32.378 49.2432 32.5938 49.2432Z"
        stroke="white"
        strokeWidth="3.90625"
      />
      <path
        d="M38.8438 63.6963C38.8438 65.4222 37.4446 66.8213 35.7188 66.8213C33.9929 66.8213 32.5938 65.4222 32.5938 63.6963C32.5938 61.9704 33.9929 60.5713 35.7188 60.5713C37.4446 60.5713 38.8438 61.9704 38.8438 63.6963Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5625"
      />
      <path
        d="M48.2188 69.9463C48.2188 71.6722 46.8196 73.0713 45.0938 73.0713C43.3679 73.0713 41.9688 71.6722 41.9688 69.9463C41.9688 68.2204 43.3679 66.8213 45.0938 66.8213C46.8196 66.8213 48.2188 68.2204 48.2188 69.9463Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5625"
      />
      <path
        d="M37.2812 74.6338C37.2812 76.3597 35.8821 77.7588 34.1562 77.7588C32.4304 77.7588 31.0312 76.3597 31.0312 74.6338C31.0312 72.9079 32.4304 71.5088 34.1562 71.5088C35.8821 71.5088 37.2812 72.9079 37.2812 74.6338Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5625"
      />
      <path
        d="M99.8729 73.304C99.2649 75.352 98.3049 77.064 96.9929 78.44C95.6809 79.816 94.1289 80.84 92.3369 81.512C90.5449 82.184 88.5769 82.52 86.4329 82.52C83.2969 82.52 80.5769 81.864 78.2729 80.552C76.0009 79.24 74.2409 77.368 72.9929 74.936C71.7769 72.504 71.1689 69.592 71.1689 66.2C71.1689 62.808 71.7769 59.896 72.9929 57.464C74.2409 55.032 76.0009 53.16 78.2729 51.848C80.5769 50.536 83.2649 49.88 86.3369 49.88C88.5129 49.88 90.4809 50.184 92.2409 50.792C94.0009 51.368 95.4889 52.264 96.7049 53.48C97.9529 54.696 98.8649 56.232 99.4409 58.088L93.4409 60.632C92.8969 58.712 92.0649 57.352 90.9449 56.552C89.8249 55.72 88.3849 55.304 86.6249 55.304C84.8969 55.304 83.3769 55.72 82.0649 56.552C80.7849 57.384 79.7929 58.616 79.0889 60.248C78.3849 61.848 78.0329 63.832 78.0329 66.2C78.0329 68.536 78.3529 70.52 78.9929 72.152C79.6649 73.784 80.6409 75.016 81.9209 75.848C83.2009 76.68 84.7689 77.096 86.6249 77.096C88.3529 77.096 89.8409 76.632 91.0889 75.704C92.3689 74.776 93.2649 73.368 93.7769 71.48L99.8729 73.304Z"
        fill="white"
      />
      <path
        d="M118.527 66.728C118.527 65.352 118.127 64.28 117.327 63.512C116.559 62.744 115.535 62.36 114.255 62.36C113.071 62.36 112.031 62.664 111.135 63.272C110.239 63.88 109.583 64.888 109.167 66.296L103.551 64.568C104.095 62.456 105.279 60.76 107.103 59.48C108.959 58.2 111.439 57.56 114.543 57.56C116.655 57.56 118.495 57.896 120.063 58.568C121.631 59.208 122.847 60.184 123.711 61.496C124.607 62.808 125.055 64.456 125.055 66.44V76.184C125.055 77.4 125.631 78.008 126.783 78.008C127.327 78.008 127.839 77.928 128.319 77.768L127.935 81.848C127.103 82.264 126.063 82.472 124.815 82.472C123.695 82.472 122.687 82.28 121.791 81.896C120.895 81.512 120.191 80.936 119.679 80.168C119.167 79.368 118.911 78.376 118.911 77.192V77L120.063 76.808C119.871 78.024 119.343 79.064 118.479 79.928C117.615 80.76 116.559 81.4 115.311 81.848C114.095 82.296 112.831 82.52 111.519 82.52C109.887 82.52 108.479 82.28 107.295 81.8C106.111 81.288 105.199 80.552 104.559 79.592C103.919 78.632 103.599 77.448 103.599 76.04C103.599 74.248 104.175 72.792 105.327 71.672C106.479 70.52 108.127 69.736 110.271 69.32L119.295 67.496L119.247 71.576L113.487 72.728C112.399 72.952 111.567 73.256 110.991 73.64C110.447 73.992 110.175 74.584 110.175 75.416C110.175 76.216 110.463 76.824 111.039 77.24C111.647 77.656 112.367 77.864 113.199 77.864C113.871 77.864 114.527 77.784 115.167 77.624C115.807 77.464 116.383 77.208 116.895 76.856C117.407 76.504 117.807 76.024 118.095 75.416C118.383 74.808 118.527 74.072 118.527 73.208V66.728Z"
        fill="white"
      />
      <path
        d="M132.409 82.04V58.04H138.313L138.697 65.24L137.881 64.472C138.265 62.904 138.873 61.608 139.705 60.584C140.537 59.56 141.545 58.808 142.729 58.328C143.945 57.816 145.273 57.56 146.713 57.56C148.377 57.56 149.849 57.88 151.129 58.52C152.441 59.16 153.465 60.136 154.201 61.448C154.969 62.728 155.353 64.344 155.353 66.296V82.04H148.777V68.168C148.777 66.12 148.377 64.712 147.577 63.944C146.777 63.144 145.737 62.744 144.457 62.744C143.561 62.744 142.681 62.968 141.817 63.416C140.985 63.832 140.297 64.52 139.753 65.48C139.241 66.44 138.985 67.72 138.985 69.32V82.04H132.409Z"
        fill="white"
      />
      <path
        d="M171.2 82.52C169.216 82.52 167.44 82.024 165.872 81.032C164.336 80.04 163.12 78.616 162.224 76.76C161.36 74.904 160.928 72.68 160.928 70.088C160.928 67.432 161.392 65.176 162.32 63.32C163.248 61.432 164.496 60.008 166.064 59.048C167.664 58.056 169.456 57.56 171.44 57.56C173.552 57.56 175.296 58.168 176.672 59.384C178.048 60.6 178.944 62.168 179.36 64.088L178.736 64.856V47.528H185.312V82.04H179.408L178.976 74.744L179.792 75.128C179.568 76.632 179.056 77.944 178.256 79.064C177.456 80.152 176.448 81 175.232 81.608C174.016 82.216 172.672 82.52 171.2 82.52ZM173.12 77.48C174.272 77.48 175.264 77.192 176.096 76.616C176.928 76.008 177.568 75.128 178.016 73.976C178.496 72.824 178.736 71.448 178.736 69.848C178.736 68.312 178.512 67.016 178.064 65.96C177.616 64.872 176.976 64.04 176.144 63.464C175.312 62.888 174.336 62.6 173.216 62.6C171.424 62.6 170.016 63.24 168.992 64.52C168 65.768 167.504 67.608 167.504 70.04C167.504 72.472 168 74.328 168.992 75.608C169.984 76.856 171.36 77.48 173.12 77.48Z"
        fill="white"
      />
      <path
        d="M214.782 58.04L205.086 83.528C204.318 85.704 203.454 87.432 202.494 88.712C201.566 89.992 200.478 90.92 199.23 91.496C198.014 92.072 196.558 92.36 194.862 92.36C193.614 92.36 192.462 92.2 191.406 91.88C190.382 91.592 189.486 91.208 188.718 90.728L190.11 85.976C190.718 86.456 191.358 86.792 192.03 86.984C192.702 87.208 193.47 87.32 194.334 87.32C195.23 87.32 195.998 87.128 196.638 86.744C197.31 86.36 197.87 85.592 198.318 84.44L199.134 82.376L196.494 76.136L189.15 58.04H196.446L201.006 72.44L202.254 77.24L203.742 72.152L208.11 58.04H214.782Z"
        fill="white"
      />
      <path
        d="M244.212 49.88C247.092 49.88 249.62 50.376 251.796 51.368C253.972 52.36 255.828 53.832 257.364 55.784L253.668 60.056C252.356 58.392 250.916 57.176 249.348 56.408C247.812 55.608 246.02 55.208 243.972 55.208C242.628 55.208 241.508 55.384 240.612 55.736C239.748 56.088 239.108 56.552 238.692 57.128C238.308 57.704 238.116 58.344 238.116 59.048C238.116 59.912 238.452 60.648 239.124 61.256C239.796 61.832 240.948 62.312 242.58 62.696L248.484 64.04C251.62 64.744 253.86 65.8 255.204 67.208C256.58 68.584 257.268 70.376 257.268 72.584C257.268 74.664 256.724 76.456 255.636 77.96C254.548 79.432 253.028 80.568 251.076 81.368C249.124 82.136 246.884 82.52 244.356 82.52C242.148 82.52 240.116 82.264 238.26 81.752C236.436 81.208 234.804 80.472 233.364 79.544C231.924 78.616 230.724 77.544 229.764 76.328L233.508 71.864C234.244 72.888 235.156 73.8 236.244 74.6C237.332 75.4 238.564 76.04 239.94 76.52C241.348 76.968 242.82 77.192 244.356 77.192C245.668 77.192 246.772 77.048 247.668 76.76C248.596 76.44 249.284 76.008 249.732 75.464C250.212 74.888 250.452 74.2 250.452 73.4C250.452 72.568 250.164 71.864 249.588 71.288C249.044 70.68 248.02 70.216 246.516 69.896L240.084 68.456C238.228 68.008 236.628 67.432 235.284 66.728C233.972 65.992 232.964 65.064 232.26 63.944C231.588 62.792 231.252 61.432 231.252 59.864C231.252 57.976 231.764 56.28 232.788 54.776C233.812 53.272 235.284 52.088 237.204 51.224C239.156 50.328 241.492 49.88 244.212 49.88Z"
        fill="white"
      />
      <path
        d="M262.862 82.04V47.528H269.438V62.12C270.206 60.584 271.262 59.448 272.606 58.712C273.95 57.944 275.47 57.56 277.166 57.56C278.83 57.56 280.302 57.88 281.582 58.52C282.894 59.16 283.918 60.136 284.654 61.448C285.422 62.728 285.806 64.344 285.806 66.296V82.04H279.23V68.216C279.23 66.136 278.83 64.712 278.03 63.944C277.23 63.144 276.206 62.744 274.958 62.744C274.03 62.744 273.134 62.968 272.27 63.416C271.438 63.832 270.75 64.536 270.206 65.528C269.694 66.488 269.438 67.768 269.438 69.368V82.04H262.862Z"
        fill="white"
      />
      <path
        d="M303.525 57.56C305.957 57.56 308.085 58.056 309.909 59.048C311.765 60.04 313.205 61.464 314.229 63.32C315.285 65.176 315.813 67.416 315.813 70.04C315.813 72.632 315.285 74.872 314.229 76.76C313.205 78.616 311.765 80.04 309.909 81.032C308.085 82.024 305.957 82.52 303.525 82.52C301.125 82.52 298.997 82.024 297.141 81.032C295.285 80.04 293.829 78.616 292.773 76.76C291.749 74.872 291.237 72.632 291.237 70.04C291.237 67.416 291.749 65.176 292.773 63.32C293.829 61.464 295.285 60.04 297.141 59.048C298.997 58.056 301.125 57.56 303.525 57.56ZM303.525 62.456C302.309 62.456 301.269 62.744 300.405 63.32C299.573 63.864 298.933 64.696 298.485 65.816C298.037 66.936 297.813 68.344 297.813 70.04C297.813 71.736 298.037 73.144 298.485 74.264C298.933 75.384 299.573 76.232 300.405 76.808C301.269 77.352 302.309 77.624 303.525 77.624C304.709 77.624 305.717 77.352 306.549 76.808C307.413 76.232 308.069 75.384 308.517 74.264C308.965 73.144 309.189 71.736 309.189 70.04C309.189 68.344 308.965 66.936 308.517 65.816C308.069 64.696 307.413 63.864 306.549 63.32C305.717 62.744 304.709 62.456 303.525 62.456Z"
        fill="white"
      />
      <path
        d="M321.315 92.12V58.04H327.219L327.651 65.672L326.835 64.808C327.123 63.4 327.651 62.152 328.419 61.064C329.219 59.976 330.227 59.128 331.443 58.52C332.659 57.88 334.019 57.56 335.523 57.56C337.475 57.56 339.219 58.056 340.755 59.048C342.291 60.04 343.491 61.464 344.355 63.32C345.251 65.144 345.699 67.368 345.699 69.992C345.699 72.616 345.251 74.872 344.355 76.76C343.459 78.616 342.227 80.04 340.659 81.032C339.123 82.024 337.363 82.52 335.379 82.52C333.267 82.52 331.507 81.928 330.099 80.744C328.723 79.56 327.779 78.072 327.267 76.28L327.891 75.272V92.12H321.315ZM333.459 77.48C335.219 77.48 336.595 76.856 337.587 75.608C338.611 74.328 339.123 72.472 339.123 70.04C339.123 67.608 338.627 65.768 337.635 64.52C336.675 63.24 335.315 62.6 333.555 62.6C332.435 62.6 331.443 62.904 330.579 63.512C329.747 64.088 329.091 64.936 328.611 66.056C328.131 67.144 327.891 68.472 327.891 70.04C327.891 71.576 328.115 72.904 328.563 74.024C329.011 75.144 329.651 76.008 330.483 76.616C331.347 77.192 332.339 77.48 333.459 77.48Z"
        fill="white"
      />
    </svg>
  );
};
