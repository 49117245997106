import React from 'react';

const IconChevronUp: React.FC = () => {
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00003 0.878906L0.939026 7.93891L3.06103 10.0609L8.00003 5.12091L12.939 10.0609L15.061 7.93891L8.00003 0.878906Z"
        fill="#7522F5"
      />
    </svg>
  );
};

export default IconChevronUp;
