import React from 'react';

export const IconSearch: React.FC = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.66634 10.9997C6.84966 10.9994 7.99888 10.6033 8.93101 9.87434L11.8617 12.805L12.8043 11.8623L9.87368 8.93167C10.603 7.99945 10.9994 6.84997 10.9997 5.66634C10.9997 2.72567 8.60701 0.333008 5.66634 0.333008C2.72567 0.333008 0.333008 2.72567 0.333008 5.66634C0.333008 8.60701 2.72567 10.9997 5.66634 10.9997ZM5.66634 1.66634C7.87234 1.66634 9.66634 3.46034 9.66634 5.66634C9.66634 7.87234 7.87234 9.66634 5.66634 9.66634C3.46034 9.66634 1.66634 7.87234 1.66634 5.66634C1.66634 3.46034 3.46034 1.66634 5.66634 1.66634Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};
