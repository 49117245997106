import React from 'react';

export const IconClock: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99997 2.00002C5.12197 2.00002 0.999969 6.12202 0.999969 11C0.999969 15.878 5.12197 20 9.99997 20C14.879 20 19 15.878 19 11C19 6.12202 14.879 2.00002 9.99997 2.00002ZM15 12H8.99997V6.00002H11V10H15V12ZM18.292 4.70802L15.282 1.70802L16.694 0.291016L19.704 3.29102L18.292 4.70802ZM3.28197 0.294016L4.69997 1.70602L1.70997 4.70602L0.292969 3.29302L3.28197 0.294016Z"
        fill="currentColor"
      />
    </svg>
  );
};
